<template>
    <div class="px-7 py-5">
      <div class="fs-5 text-dark fw-bolder">Filter Options</div>
    </div>

    <div class="separator border-gray-200"></div>
    <div class="px-7 py-5">
      <el-form
            id="financial_filter_form"
            :model="data"
            ref="formRef"
            class="form"
          >
      <div class="mb-6">
        <label class="form-label fw-bold">Date Range:</label>
        <div>
          <el-form-item prop="transactionDates">
            <el-date-picker
              v-model="data.record_dates"
              type="daterange"
              range-separator="~"
              start-placeholder="Start date"
              end-placeholder="End date"
              class="w-80"
              format="MM/DD/YYYY">
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
      <div class="mb-6">
        <label class="form-label fw-bold">Transaction Type:</label>
        <div class="d-flex" style="justify-content: flex-end;">
          <el-form-item prop="transactionTypes" style="display:block;">
            <el-checkbox-group
              v-model="data.record_types"
              size="medium"
            >
              <el-checkbox-button
                v-for="type in transactionTypes"
                :key="type"
                :label="type"
              >
                {{ type }} 
              </el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>

      <div class="mb-6">
        <label class="form-label fw-bold">Payment Method Type:</label>
        <div class="d-flex" style="justify-content: flex-end;">
          <el-form-item prop="paymentMethods" style="display:block;">
            <el-checkbox-group
              v-model="data.payment_methods"
              size="medium"
            >
              <el-checkbox-button
                v-for="type in paymentMethodTypes"
                :key="type"
                :label="type"
              >
                {{ type }} 
              </el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>

      <div class="mb-6">
        <label class="form-label fw-bold">Student:</label>
        <div class="d-flex">
          <el-form-item prop="name" style="width: 100%;">
            <Multiselect v-model="data.students" v-bind="studentMultiselect"></Multiselect>
          </el-form-item>
        </div>
      </div>

      <div class="mb-6">
        <label class="form-label fw-bold">Amount Range:</label>
        <div style="display:block;">
          <el-form-item prop="minAmount" style="display:inline-block;">
            <el-input-number v-model="data.min_amount" :precision="2" :step="0.1" :max="100000" />
          </el-form-item>
          ~
          <el-form-item prop="maxAmount" style="display:inline-block;">
            <el-input-number v-model="data.max_amount" :precision="2" :step="0.1" :max="100000" />
          </el-form-item>
        </div>
      </div>

      <div class="mb-6">
        <label class="form-label fw-bold">Memo:</label>
        <div class="d-flex">
          <el-form-item prop="memo" style="width: 100%;">
            <input 
              v-model="data.memo" 
              style="width:100%;"
              class="form-control form-control-lg form-control-solid" 
            />
          </el-form-item>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          @click.prevent="updateFilter()"
        >
          Apply
        </button>
      </div>
      </el-form>
    </div>
</template>

<script lang="ts">
import { setFinanceFilter } from "@/core/helpers/finance";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "FinancialFilter",
  components: {
    Multiselect
  },
  props:{
    transactionTypes:[],
    paymentMethodTypes:[],
    students: Array as PropType<any[]>,
    getFinanceRecords : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const {students} = toRefs(props);
    const formRef = ref<null | HTMLFormElement>(null);
    const data = computed(() => {
      return store.getters.currentFinanceFilter.financeFilter;
    });

    const studentsData = computed(() => {
      return students.value != undefined && students.value.length > 0 ? students.value.map(x=>{return {value:x.id, label:x.first_name+" "+x.last_name+"("+x.student_id+")"}}) :[];
    });

    const studentMultiselect = ref({
      mode: "tags",
      options: studentsData,
      searchable: true,
      createTag: true
    });

    const updateFilter = () => {
      setFinanceFilter(data.value);
      props.getFinanceRecords();
      MenuComponent.hideDropdowns(undefined);
    }

    return {
      data,
      formRef,
      updateFilter,
      studentMultiselect
    };
  },
});
</script>

<style scoped>
* >>> .el-input-number
{
  width:170px;
}

* >>> .el-form-item
{
  margin-bottom:0px;
}

* >>> .el-form-item__content
{
  line-height: 5px;
}
</style>

