import store from "@/store";
import { Actions } from "@/store/enums/SmsStoreEnums";
import { FinanceFilter } from "@/types/FinanceModel"

export const setFinanceFilter = (
  financeFilter: FinanceFilter,
): void => {
  store.dispatch(Actions.SET_FINANCEFILTER_ACTION, {
    financeFilter : financeFilter,
  });
};