
import { computed, defineComponent, onBeforeMount, ref, toRefs } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { LIGHT_EFFECT } from "element-plus";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "create-record-modal",
  components: {
    Multiselect
  },
  props:{
    transactionTypes:[],
    paymentMethodTypes:[],
    paymentTypes: [] as any,
    students:[] as any
  },
  emits :['getFinanceRecords'],
  setup(props,{emit}) {
    const store = useStore();
    const { students, paymentTypes } = toRefs(props);
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const user = store.getters.currentUser;
    const selectedStudents = ref([] as any);

    const recordData = ref
    ({
      record_type :'',
      record_date  :'',
      students  : undefined,
      amount  : 0,
      payment_method  :'',
      payment_type  :'',
      memo  :'',
      user_id: user.id
    });

    const resetForm = () =>{
      recordData.value.record_type ='';
      recordData.value.record_date ='';
      recordData.value.students  =undefined;
      recordData.value.amount = 0;
      recordData.value.payment_method ='';
      recordData.value.payment_type ='';
      recordData.value.memo ='';
      selectedStudents.value = [];
    }

    const getStudent=(queryString, callback)=>{
        let selectedList = students.value.filter(data => (!queryString 
                            || (data.first_name +" "+ data.last_name +"("+data.student_id+")").toLowerCase().includes(queryString.toLowerCase())));
        callback(selectedList);
    }

    const resetPaymentType = () =>{
        recordData.value.payment_type = '';
    }

    const filteredPaymentTypes = computed(() =>{
        var currentPaymentTypes = [];

        if(recordData.value.record_type){
            currentPaymentTypes = paymentTypes.value[recordData.value.record_type];
        }

        return currentPaymentTypes;
    })
    
    const rules = ref({
      record_type: [
        {
          required: true,
          message: "Please select transaction type",
          trigger: "blur",
        },
      ],
      record_date: [
        {
          required: true,
          message: "Please select date",
          trigger: "change",
        },
      ],
      amount: [
        {
          required: true,
          message: "Please enter valid amount",
          trigger: "change",
        },
      ],
      payment_method: [
        {
          required: true,
          message: "Please select payment method",
          trigger: "change",
        },
      ],
      payment_type: [
        {
          required: true,
          message: "Please select transaction type",
          trigger: "change",
        },
      ],
    });

    const createFinanceRecords = (formData) : any => {
      return new Promise<void>((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("finance/records", formData)
          .then(() =>{
            resolve();
          })
          .catch(({ response }) => {
              loading.value = false;
              Swal.fire({
                text: "Failed to create the new transaction",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
          });
      });
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          recordData.value.students = selectedStudents.value; 
             
          createFinanceRecords(recordData.value).then(() => {
            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              emit('getFinanceRecords');
              loading.value = false;
              let closeEditBtn = document.getElementById('close_finance_record_btn');
              closeEditBtn?.click();
            });
          });
        } else {
          Swal.fire({
            text: "Please fill out all required fields",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    const studentsData = computed(() => {
      return students.value != undefined && students.value.length > 0 ? students.value.map(x=>{return {value:x.id, label:x.first_name+" "+x.last_name+"("+x.student_id+")"}}) :[];
    });

    const studentMultiselect = ref({
      mode: "tags",
      options: studentsData,
      searchable: true,
      createTag: true
    });

    return {
      recordData,
      submit,
      rules,
      loading,
      formRef,
      getStudent,
      selectedStudents,
      filteredPaymentTypes,
      resetPaymentType,
      resetForm,
      studentMultiselect
    };
  },
});
