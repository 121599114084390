
import { setFinanceFilter } from "@/core/helpers/finance";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "FinancialFilter",
  components: {
    Multiselect
  },
  props:{
    transactionTypes:[],
    paymentMethodTypes:[],
    students: Array as PropType<any[]>,
    getFinanceRecords : {
      type:Function,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const {students} = toRefs(props);
    const formRef = ref<null | HTMLFormElement>(null);
    const data = computed(() => {
      return store.getters.currentFinanceFilter.financeFilter;
    });

    const studentsData = computed(() => {
      return students.value != undefined && students.value.length > 0 ? students.value.map(x=>{return {value:x.id, label:x.first_name+" "+x.last_name+"("+x.student_id+")"}}) :[];
    });

    const studentMultiselect = ref({
      mode: "tags",
      options: studentsData,
      searchable: true,
      createTag: true
    });

    const updateFilter = () => {
      setFinanceFilter(data.value);
      props.getFinanceRecords();
      MenuComponent.hideDropdowns(undefined);
    }

    return {
      data,
      formRef,
      updateFilter,
      studentMultiselect
    };
  },
});
